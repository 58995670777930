// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EducationalProfessional_buttonContainer__SN\\+ji {\n  display: flex;\n  padding: 20px;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n\n.EducationalProfessional_buttonContainerBottom__XNeaX {\n  display: flex;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/educational/EducationalProfessional.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".buttonContainer {\n  display: flex;\n  padding: 20px;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n\n.buttonContainerBottom {\n  display: flex;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "EducationalProfessional_buttonContainer__SN+ji",
	"buttonContainerBottom": "EducationalProfessional_buttonContainerBottom__XNeaX"
};
export default ___CSS_LOADER_EXPORT___;
