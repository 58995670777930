// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationBox_noticePanelGrid__O2LOr {\n  background-color: #f5f5f4;\n  border-radius: 5px;\n  /*box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);*/\n  justify-content: left;\n  align-items: center;\n  padding: 20px;\n  margin-left: 0.5%;\n  margin-bottom: 1%;\n  cursor: pointer;\n}\n\n.NotificationBox_pStyle__E-nXU {\n  margin-left: 13.5;\n  font-size: 15;\n  padding: 3;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/notificationBox/NotificationBox.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,2DAA2D;EAC3D,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".noticePanelGrid {\n  background-color: #f5f5f4;\n  border-radius: 5px;\n  /*box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);*/\n  justify-content: left;\n  align-items: center;\n  padding: 20px;\n  margin-left: 0.5%;\n  margin-bottom: 1%;\n  cursor: pointer;\n}\n\n.pStyle {\n  margin-left: 13.5;\n  font-size: 15;\n  padding: 3;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noticePanelGrid": "NotificationBox_noticePanelGrid__O2LOr",
	"pStyle": "NotificationBox_pStyle__E-nXU"
};
export default ___CSS_LOADER_EXPORT___;
