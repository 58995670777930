import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import {
  CVS110,
  CVS111,
  CVS112,
  CVS113,
  CVS114,
  CVS120,
  CVS121,
  CVS122,
  CVS130,
  CVS131,
  CVS140,
  CVS210,
  CVS211,
  CVS220,
  CVS221,
  CVS222,
  CVS230,
  CVS231,
  CVS232,
  CVS233,
  CVS234,
  CVS310,
  CVS311,
  CVS320,
  CVS330,
  CVS331,
  CVS410,
  CVS420,
  CVS430,
  CVS431,
  CVS440,
  CVS450,
  CVS460,
} from '../../features/professional-educational'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'

export function EducationalsProfessionalVesical(props: RouteProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState<number>(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <CurrentNavHeader title={'Módulo 1. Introducción'} />
      <Box
        className={generic.pageContainer}
        style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
        <Box
          className={generic.pageContainer}
          style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
          {activeStep === 0 && <CVS110 />}
          {activeStep === 1 && <CVS111 />}
          {activeStep === 2 && <CVS112 />}
          {activeStep === 3 && <CVS113 />}
          {activeStep === 4 && <CVS114 />}
          {activeStep === 5 && <CVS120 />}
          {activeStep === 6 && <CVS121 />}
          {activeStep === 7 && <CVS122 />}
          {activeStep === 8 && <CVS130 />}
          {activeStep === 9 && <CVS131 />}
          {activeStep === 10 && <CVS140 />}
          {activeStep === 11 && <CVS210 />}
          {activeStep === 12 && <CVS211 />}
          {activeStep === 13 && <CVS220 />}
          {activeStep === 14 && <CVS221 />}
          {activeStep === 15 && <CVS222 />}
          {activeStep === 16 && <CVS230 />}
          {activeStep === 17 && <CVS231 />}
          {activeStep === 18 && <CVS232 />}
          {activeStep === 19 && <CVS233 />}
          {activeStep === 20 && <CVS234 />}
          {activeStep === 21 && <CVS310 />}
          {activeStep === 22 && <CVS311 />}
          {activeStep === 23 && <CVS320 />}
          {activeStep === 24 && <CVS330 />}
          {activeStep === 25 && <CVS331 />}
          {activeStep === 26 && <CVS410 />}
          {activeStep === 27 && <CVS420 />}
          {activeStep === 28 && <CVS430 />}
          {activeStep === 29 && <CVS431 />}
          {activeStep === 30 && <CVS440 />}
          {activeStep === 31 && <CVS450 />}
          {activeStep === 32 && <CVS460 />}

          <MobileStepper
            style={{ display: 'flex', transform: 'translate(175%, 50%)' }}
            variant="dots"
            steps={33}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400, flexGrow: 1 }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === 32}>
                {t('next')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {t('back')}
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  )
}
