import { Box } from '@mui/material'
import generic from '../../assets/generic.module.css'
import style from '../statistics/Stats.module.css'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { Pager } from '../../components/table/types'
import { URINATION_SERVICE_KEY } from '../../modules/urination'
import { getUrinationContainer } from '../../container/urination-module'
import { UrinationService } from '../../modules/urination/services/UrinationService'
import { Urination } from '../../modules/urination/models/Urination'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import { TableMicturitionMonitoring } from 'components/table/TableMicturionMonitoring'
import { createStyles, makeStyles } from '@material-ui/core'
import { fromModel, UrinationDTO } from '../../modules/urination/models/UrinationDTO'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'

type UrinationProps = {
  id: string
}

const urinationService = getUrinationContainer().get<UrinationService>(URINATION_SERVICE_KEY)

export const Table = (props: UrinationProps) => {
  const { t } = useTranslation()
  const dateMinusWeek = new Date()
  dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
  const [startDate, setStartDate] = useState<Date>(dateMinusWeek)
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [items, setItems] = useState<Urination[]>([])
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<Urination>>({
    field: 'date',
    desc: true,
  })

  useEffect(() => {
    urinationService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [
            new QueryParam<Urination>('PatientID', props.id || ''),
            new QueryParam<Urination>('startDate', startDate.toISOString()),
            new QueryParam<Urination>('endDate', finishDate.toISOString()),
          ],
          sort: [{ field: 'drinkDate' }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setIsLoading(false)
      })
  }, [isLoading, startDate, finishDate, page, rowsPerPage])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
    setIsLoading(false)
  }, [page, count, rowsPerPage])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const handleDate = (e: MaterialUiPickersDate, name: string) => {
    if (e && name == 'startDate') {
      let d = e.toDate()
      setStartDate(new Date(new Date(d.getFullYear(), d.getMonth(), d.getDate())))
      setIsLoading(!isLoading)
    }
    if (e && name == 'finishDate') {
      let d = e.toDate()
      setFinishDate(new Date(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)))
      setIsLoading(!isLoading)
    }
  }

  const handleClean = () => {
    setFinishDate(new Date())
    const dateMinusWeek = new Date()
    dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
    setStartDate(dateMinusWeek)
  }

  const handleApply = () => {
    //TODO reload charts with new DATA
  }

  const handleDownloadExcel = () => {
    const itemsDTO: UrinationDTO[] = []
    items.forEach((item) => {
      itemsDTO.push(fromModel(item))
    })

    const language = navigator.language.split('-')[0].toUpperCase()
    urinationService.getExcelFromUrinationDiaries(itemsDTO, language).subscribe((res) => {
      if (res) {
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.href = url
        a.download = 'urination-diaries.xlsx' // Nombre del archivo a descargar
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }
    })
  }

  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    })
  )

  const classes = useStyles()

  return (
    <>
      <Box className={style.buttonBack}>
        <AppButton
          theme={ButtonTheme.NewPrimaryLightWithoutWidth}
          type={'button'}
          label={t('returnToPatient')}
          handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
        />
      </Box>
      <Box className={style.titleBox}>
        <h3 className={style.title}>{t('urinationMonitoring')}</h3>
      </Box>
      <Box className={generic.pageContainer}>
        <Box className={style.filtersContainer}>
          <Box className={style.titleContainer}>
            <h4 style={{ fontSize: '20px', color: '#000', fontWeight: 'normal' }}>
              {t('filters')}
            </h4>
          </Box>
          <Box className={style.filtersDetailContainer}>
            <Box display="flex" flexDirection="row" alignItems={'center'}>
              <h5 style={{ color: '#000' }}>{t('from')}</h5>
              <KeyboardDatePicker
                style={{ width: '150px', marginLeft: '10px', marginRight: '10px' }}
                InputProps={{
                  className: classes.componentStyle,
                  style: { fontFamily: 'Open-sans, sans-serif' },
                }}
                key={'startDate'}
                id={'startDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(e) => handleDate(e, 'startDate')}
                size={'small'}
              />
              <h5 style={{ color: '#000' }}>{t('to')}</h5>
              <KeyboardDatePicker
                style={{ width: '150px', marginLeft: '10px' }}
                InputProps={{
                  className: classes.componentStyle,
                  style: { fontFamily: 'Open-sans, sans-serif' },
                }}
                key={'finishDate'}
                id={'finishDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format={'DD/MM/YYYY'}
                value={finishDate}
                onChange={(e) => handleDate(e, 'finishDate')}
                size={'small'}
              />
            </Box>
            <Box className={style.secondRow} style={{ marginBottom: '30px' }}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Box className={style.buttonContainer}>
                  <AppButton
                    theme={ButtonTheme.BasicTrasparent}
                    type="button"
                    label={t('cleanFilters')}
                    handler={handleClean}
                  />
                </Box>
                <Box>
                  <AppButton
                    theme={ButtonTheme.NewPrimaryLight}
                    type="button"
                    label={t('apply')}
                    handler={handleApply}
                  />
                </Box>
                <Box ml={2}>
                  <AppButton
                    theme={ButtonTheme.NewPrimaryLightWithoutWidth}
                    type="button"
                    label={t('downloadTable')}
                    handler={handleDownloadExcel}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ overflowX: 'auto' }}>
          <TableMicturitionMonitoring items={items} pager={pager} />
        </Box>
      </Box>
    </>
  )
}
