// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Configuration_configurationList__U5pY5 {\n  width: calc(100% - 20px);\n  background-color: var(--color-white);\n  border-radius: 2rem;\n}\n\n.Configuration_configurationLink__VVUnl {\n  width: 100%;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.Configuration_configurationItem__rBYT6 {\n  column-gap: 1rem;\n  display: flex;\n  justify-content: start;\n  margin-left: 35px;\n}\n\n.Configuration_version__VbPgj {\n  padding: 10px;\n  color: #757575;\n}\n\n.Configuration_configurationSeparator__r4QSW {\n  margin: 0;\n  width: 95%;\n}\n\n.Configuration_configurationItemLabel__a2QYD {\n  font-size: 1.2rem;\n  text-align: left;\n  display: block;\n}\n\n.Configuration_icon__g236Y {\n  width: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/configuration/Configuration.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".configurationList {\n  width: calc(100% - 20px);\n  background-color: var(--color-white);\n  border-radius: 2rem;\n}\n\n.configurationLink {\n  width: 100%;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.configurationItem {\n  column-gap: 1rem;\n  display: flex;\n  justify-content: start;\n  margin-left: 35px;\n}\n\n.version {\n  padding: 10px;\n  color: #757575;\n}\n\n.configurationSeparator {\n  margin: 0;\n  width: 95%;\n}\n\n.configurationItemLabel {\n  font-size: 1.2rem;\n  text-align: left;\n  display: block;\n}\n\n.icon {\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configurationList": "Configuration_configurationList__U5pY5",
	"configurationLink": "Configuration_configurationLink__VVUnl",
	"configurationItem": "Configuration_configurationItem__rBYT6",
	"version": "Configuration_version__VbPgj",
	"configurationSeparator": "Configuration_configurationSeparator__r4QSW",
	"configurationItemLabel": "Configuration_configurationItemLabel__a2QYD",
	"icon": "Configuration_icon__g236Y"
};
export default ___CSS_LOADER_EXPORT___;
