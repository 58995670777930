export enum Source {
  FAQ = 1,
  InterestedNews = 2,
  Objectives = 3,
  Symptoms = 4,
  UrinaryDiary = 5,
  FecalDiary = 6,
  Questionnaires = 7,
  EducationalLessons = 8,
}

export const sources = (): Record<Source, string> => ({
  [Source.FAQ]: 'faq',
  [Source.InterestedNews]: 'interestedNews',
  [Source.Objectives]: 'objetives',
  [Source.Symptoms]: 'symptoms',
  [Source.UrinaryDiary]: 'urinaryDiary',
  [Source.FecalDiary]: 'fecalDiary',
  [Source.Questionnaires]: 'questionnaires',
  [Source.EducationalLessons]: 'educationalLessons',
})
