export { Educationals } from './Educational'
export { EducationalsProfessionalVesical } from './EducationalProfessionalVesical'
export { EducationalsProfessionalIntestinal } from './EducationalProfessionalIntestinal'
export { EducationalsProfessionalIntestinal11 } from './intestinal/EducationalProfessionalIntestinal1.1'
export { EducationalsProfessionalIntestinal21 } from './intestinal/EducationalProfessionalIntestinal2.1'
export { EducationalsProfessionalIntestinal22 } from './intestinal/EducationalProfessionalIntestinal2.2'
export { EducationalsProfessionalIntestinal23 } from './intestinal/EducationalProfessionalIntestinal2.3'
export { EducationalsProfessionalIntestinal24 } from './intestinal/EducationalProfessionalIntestinal2.4'
export { EducationalsProfessionalIntestinal31 } from './intestinal/EducationalProfessionalIntestinal3.1'
export { EducationalsProfessionalIntestinal32 } from './intestinal/EducationalProfessionalIntestinal3.2'
export { EducationalsProfessionalIntestinal33 } from './intestinal/EducationalProfessionalIntestinal3.3'
export { EducationalsProfessionalVesical11 } from './vesical/EducationalProfessionalVesical1.1'
export { EducationalsProfessionalVesical12 } from './vesical/EducationalProfessionalVesical1.2'
export { EducationalsProfessionalVesical13 } from './vesical/EducationalProfessionalVesical1.3'
export { EducationalsProfessionalVesical14 } from './vesical/EducationalProfessionalVesical1.4'
export { EducationalsProfessionalVesical21 } from './vesical/EducationalProfessionalVesical2.1'
export { EducationalsProfessionalVesical22 } from './vesical/EducationalProfessionalVesical2.2'
export { EducationalsProfessionalVesical23 } from './vesical/EducationalProfessionalVesical2.3'
export { EducationalsProfessionalVesical31 } from './vesical/EducationalProfessionalVesical3.1'
export { EducationalsProfessionalVesical32 } from './vesical/EducationalProfessionalVesical3.2'
export { EducationalsProfessionalVesical33 } from './vesical/EducationalProfessionalVesical3.3'
export { EducationalsProfessionalVesical41 } from './vesical/EducationalProfessionalVesical4.1'
export { EducationalsProfessionalVesical42 } from './vesical/EducationalProfessionalVesical4.2'
export { EducationalsProfessionalVesical43 } from './vesical/EducationalProfessionalVesical4.3'
export { EducationalsProfessionalVesical44 } from './vesical/EducationalProfessionalVesical4.4'
export { EducationalsProfessionalVesical45 } from './vesical/EducationalProfessionalVesical4.5'
export { EducationalsProfessionalVesical46 } from './vesical/EducationalProfessionalVesical4.6'
export { EducationalsProfessionalVesical51 } from './vesical/EducationalProfessionalVesical5.1'
