import { Box, Divider, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { PatientDTO } from '../../modules/patients/models/PatientDTO'
import styles from '../user-profile/Editor.module.css'
import style from '../patients/Patients.module.css'
import { useTranslation } from 'react-i18next'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { UserGender } from '../../modules/users/enums/UserGender'
import { ChangeEvent, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { communities, provinces } from '../professionals/communities'
import { createStyles, makeStyles } from '@material-ui/core'

interface GeneralViewProps {
  patient: PatientDTO
  handleChangeCommunity: (c: string) => void
  handleChangeProvince: (c: string) => void
  handleChangePatientData: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChangeDOB: (d: Date) => void
  handleChangeGender: (e: string) => void
  handleChangeProvinceCommunity: (prov: string, com: string) => void
}

export function PatientDetailGeneral(p: GeneralViewProps) {
  const { t } = useTranslation()
  const [patient, setPatient] = useState<PatientDTO>(p.patient)
  const [community, setCommunity] = useState<string>('')
  const { innerWidth } = window

  useEffect(() => {
    setCommunity(p.patient.autonomousCommunity)
  }, [])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPatient(Object.assign({ ...patient }, { [e.target.name]: e.target.value }))
    p.handleChangePatientData(e)
  }

  const handleChangeCommunity = (c: string) => {
    setPatient(Object.assign({ ...patient }, { autonomousCommunity: c }))
    p.handleChangeCommunity(c)
  }

  const handleChangeProvince = (c: string) => {
    setPatient(Object.assign({ ...patient }, { province: c }))
    p.handleChangeProvince(c)
  }

  const handleChangeDOB = (d: Date) => {
    setPatient(Object.assign({ ...patient }, { dob: d }))
    p.handleChangeDOB(d)
  }

  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    })
  )

  const classes = useStyles()

  return (
    <>
      <Box className={style.generalContainer}>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('internalID')}</h4>
            <TextField
              style={{
                width: window.innerWidth > 1025 ? '350px' : '200px',
                fontFamily: 'Open-sans, sans-serif',
              }}
              id={'internalID'}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              key={'internalID'}
              name="internalID"
              className={styles.textField}
              value={patient.internalID}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('dischargeDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '150px', marginBottom: '40px' }}
              InputProps={{
                className: classes.componentStyle,
                style: { fontFamily: 'Open-sans, sans-serif' },
              }}
              key={'registerDate'}
              id={'registerDate'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={patient.createdAt}
              size={'small'}
              inputProps={{ readOnly: true }}
              onChange={() => {}}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('name') + ' *'}</h4>
            <TextField
              style={{
                width:
                  window.innerWidth > 1025 ? '350px' : window.innerWidth < 769 ? '120px' : '200px',
              }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'firstName'}
              key={'firstName'}
              name="firstName"
              className={styles.textField}
              value={patient.firstName}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('lastName') + ' *'}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'lastName'}
              key={'lastName'}
              name="lastName"
              className={styles.textField}
              value={patient.lastName}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('gender') + ' *'}</h4>
            <Select
              value={
                patient.gender == UserGender.Female
                  ? t('female')
                  : patient.gender == UserGender.Male
                  ? t('male')
                  : t('noneOfTheAbove')
              }
              name="gender"
              inputProps={{
                'aria-label': 'Gender',
                style: { fontFamily: 'Open-sans, sans-serif' },
              }}
              id="my-simple-select"
              sx={{
                '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              onChange={(e) => {
                e.target.value == t('male')
                  ? setPatient(Object.assign({ ...patient }, { gender: UserGender.Male }))
                  : e.target.value == t('female')
                  ? setPatient(Object.assign({ ...patient }, { gender: UserGender.Female }))
                  : setPatient(Object.assign({ ...patient }, { gender: UserGender.NoneOfTheAbove }))
                p.handleChangeGender(e.target.value || '')
              }}
              style={{
                width: innerWidth < 900 ? '105px' : '233px',
                marginRight: '20px',
                minHeight: '40px',
                height: '40px',
                fontFamily: 'Open-sans, sans-serif',
              }}
            >
              <MenuItem value={t('female')}>{t('female')}</MenuItem>
              <MenuItem value={t('male')}>{t('male')}</MenuItem>
              <MenuItem value={t('noneOfTheAbove')}>{t('noneOfTheAbove')}</MenuItem>
            </Select>
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('birthDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '150px' }}
              InputProps={{
                className: classes.componentStyle,
                style: { fontFamily: 'Open-sans, sans-serif' },
              }}
              key={'birthDate'}
              id={'birthDate'}
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={patient.dob}
              size={'small'}
              onChange={(e) => e && handleChangeDOB(new Date(e.toDate()))}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('contactPhone')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'contactPhone'}
              key={'contactPhone'}
              name="contactPhone"
              className={styles.textField}
              value={patient.contactPhone}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('mobilePhone')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'mobilePhone'}
              key={'mobilePhone'}
              name="mobilePhone"
              className={styles.textField}
              value={patient.mobilePhone}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('email') + ' *'}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'email'}
              key={'email'}
              name="email"
              className={styles.textField}
              value={patient.email}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
              disabled={true}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('city')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'city'}
              key={'city'}
              name="city"
              className={styles.textField}
              value={patient.city}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('autonomousCommunity')}</h4>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={communities}
                value={{ label: community }}
                onChange={(_, b) => {
                  setCommunity(b?.label || '')
                  handleChangeCommunity(b?.label || '')
                  handleChangeProvince('')
                }}
                sx={{
                  width: 230,
                  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                    fontFamily: `Open-sans, sans-serif !important`,
                  },
                }}
                style={{ width: innerWidth < 1025 ? '170px' : '233px' }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Box>
            <Box className={styles.textFieldBox} style={{ marginLeft: 20 }}>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('province')}</h4>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={{ label: patient.province, value: patient.province }}
                options={
                  community == '' ? provinces : provinces.filter((p) => p.value == community)
                }
                sx={{
                  width: 230,
                  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                    fontFamily: `Open-sans, sans-serif !important`,
                  },
                }}
                style={{ width: innerWidth < 1025 ? '170px' : '233px' }}
                renderInput={(params) => <TextField {...params} size="small" />}
                onChange={(_, b) => {
                  setCommunity(b?.value || '')
                  handleChangeCommunity(b?.value || '')
                  handleChangeProvince(b?.label || '')
                  p.handleChangeProvinceCommunity(b?.label || '', b?.value || '')
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('postalCode')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'zip'}
              key={'zip'}
              name="zip"
              className={styles.textField}
              value={patient.zip}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('country')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'country'}
              key={'country'}
              name="country"
              className={styles.textField}
              value={patient.country}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
        </Box>
        <Divider style={{ marginTop: '50px', backgroundColor: '#000' }} />
        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          <h2 style={{ color: '#000' }}>{t('userAccess')}</h2>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('userMail')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'email'}
              key={'email'}
              name="email"
              className={styles.textField}
              value={patient.email}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('password')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'password'}
              key={'password'}
              name="password"
              className={styles.textField}
              value={''}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
        </Box>
        <Divider style={{ marginTop: '50px', backgroundColor: '#000' }} />
        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          <h2 style={{ color: '#000' }}>{t('carerData')}</h2>
          <Box display={'flex'}>
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('name') + ' *'}</h4>
              <TextField
                style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                id={'firstName'}
                key={'firstName'}
                name="firstName"
                className={styles.textField}
                value={''}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('lastName') + ' *'}</h4>
              <TextField
                style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                id={'lastName'}
                key={'lastName'}
                name="lastName"
                className={styles.textField}
                value={''}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
          <Box display={'flex'}>
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('contactPhone')}</h4>
              <TextField
                style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                id={'contactPhone'}
                key={'contactPhone'}
                name="contactPhone"
                className={styles.textField}
                value={patient.contactPhone}
                type="name"
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('email')}</h4>
              <TextField
                style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                id={'email'}
                key={'email'}
                name="email"
                className={styles.textField}
                value={''}
                type="name"
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
        </Box>
        <Divider style={{ marginTop: '50px', backgroundColor: '#000' }} />
        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          <h2 style={{ color: '#000' }}>{t('carerAccess')}</h2>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('userMail')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'email'}
              key={'email'}
              name="email"
              className={styles.textField}
              value={''}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('password')}</h4>
            <TextField
              style={{ width: window.innerWidth > 1025 ? '350px' : '200px' }}
              sx={{
                /*'.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },*/
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#000',
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              id={'password'}
              key={'password'}
              name="password"
              className={styles.textField}
              value={''}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={handleInput}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
