// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_table__cP3HK {\n  font-family: arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n  border-color: #bfdff2;\n  border: 1px solid #dddddd;\n}\n\n.Table_td__\\+jjUK,\nth {\n  border: 1px solid #dddddd;\n  text-align: center;\n  padding: 8px;\n}\n\n.Table_tr__RwcJl {\n  background-color: #bfdff2;\n}\n", "",{"version":3,"sources":["webpack://./src/features/professional-educational/contents/vesical/Table.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,WAAW;EACX,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".table {\n  font-family: arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n  border-color: #bfdff2;\n  border: 1px solid #dddddd;\n}\n\n.td,\nth {\n  border: 1px solid #dddddd;\n  text-align: center;\n  padding: 8px;\n}\n\n.tr {\n  background-color: #bfdff2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "Table_table__cP3HK",
	"td": "Table_td__+jjUK",
	"tr": "Table_tr__RwcJl"
};
export default ___CSS_LOADER_EXPORT___;
