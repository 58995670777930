import { ChangeEvent, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import {
  IRolesService,
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import loginWeb2 from './assets/brand_logo/Logo-Higea.svg'
import { Box } from '@material-ui/core'
import { AppButton, ButtonTheme } from './components/app-button/AppButton'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { emptyUserDTO, UserDTO } from 'modules/users/models/User'
import { FormCard } from 'components/form-card/FormCard'
import { MenuItem, Select, SelectChangeEvent, Modal } from '@mui/material'
import { Role } from 'modules/users/models/Role'
import { communities, provinces } from './features/professionals/communities'
import { LegalTermsModal } from 'components/modal/LegalTermsModal'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useSnackbar } from 'notistack'
import { Query, QueryParam } from 'common/api/Query'
import style from './components/modal/CustomModal.module.css'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = getUserContainer().get<IRolesService>(ROLES_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

type RProfessionalProps = {
  goToLogin: () => void
}

export default function ProfessionalRegister(props: RProfessionalProps) {
  const { t } = useTranslation()
  const [openModalPass, setOpenModalPass] = useState<boolean>(false)
  const [recoverEmail, setRecoverEmail] = useState<string>('')
  const [professionalData, setProfessionalData] = useState<UserDTO>(emptyUserDTO())
  const [professionalRoles, setProfessionalRoles] = useState<Role[]>()
  const [legalTermsAccepted, setLegalTermsAccepted] = useState<boolean>(false)
  const [termOfUseAccepted, setTermOfUseAccepted] = useState<boolean>(false)
  const [dataTreatmentAccepted, setDataTreatmentAccepted] = useState<boolean>(false)
  const [cookiesPolicyAccepted, setCookiesPolicyAccepted] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [modalMustAllAccept, setModalMustAllAccept] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState<string>('')

  useEffect(() => {
    roleService.getProfessionals().subscribe((res) => {
      setProfessionalRoles(res)
    })
  }, [])

  const handleClose = () => {
    setOpenModalPass(false)
  }

  const handleTermsOfUse = () => {
    setTermOfUseAccepted(!termOfUseAccepted)
  }

  const handleDataTreatment = () => {
    setDataTreatmentAccepted(!dataTreatmentAccepted)
  }

  const handleCookiesPolicy = () => {
    setCookiesPolicyAccepted(!cookiesPolicyAccepted)
  }

  const handleSendNewPassword = () => {
    loggedUserService.sendRecoverPassword(recoverEmail)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setProfessionalData(Object.assign({ ...professionalData }, { [e.target.name]: e.target.value }))
  }

  const handleSelectRole = (e: SelectChangeEvent) => {
    setProfessionalData(Object.assign({ ...professionalData }, { [e.target.name]: e.target.value }))
  }

  const validateRegister = () => {
    if (
      professionalData.firstName != '' &&
      professionalData.lastName != '' &&
      professionalData.phone != '' &&
      professionalData.email != '' &&
      professionalData.roleID != '' &&
      professionalData.autonomousCommunity != '' &&
      professionalData.province != '' &&
      professionalData.centerID != ''
    ) {
      setError('')
      setModalOpened(true)
    } else {
      enqueueSnackbar(t('allFieldsRequired'), { variant: 'warning' })
      setError(t('allFieldsRequired'))
    }
  }

  const handleConfirm = () => {
    if (termOfUseAccepted && dataTreatmentAccepted && cookiesPolicyAccepted) {
      setLegalTermsAccepted(true)
      setModalOpened(false)
      handleSave()
    } else {
      setModalMustAllAccept(true)
    }
    /* if (legalTermsAccepted) {
      setModalOpened(false)
      legalTermsAccepted && handleSave()
    }*/
  }

  const handleSave = () => {
    userService.isRegistered(professionalData.email).subscribe((isRegistered) => {
      if (!isRegistered) {
        userService.add(professionalData).subscribe((res) => {
          enqueueSnackbar(t('savedProffesionalRegister'), { variant: 'success' })
          props.goToLogin()
        })
      } else {
        setProfessionalData(Object.assign({ ...professionalData }, { email: '' }))
        enqueueSnackbar(t('userAlreadyRegistered'), { variant: 'error' })
      }
    })
  }

  return (
    <div className={styles.background}>
      <Container component="div" className={styles.mainContainer}>
        <img
          src={loginWeb2}
          alt="Logo"
          style={{ width: '20%', marginTop: '-1%', marginBottom: '1.5%' }}
        />
        <div className={styles.container}>
          <Box>
            <FormCard>
              <form>
                <Box className={styles.fieldsContainer}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      marginTop: '-5%',
                    }}
                  >
                    <h2 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                      {t('addProfessionals')}
                    </h2>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>{t('name') + ' *'}</h4>
                      <TextField
                        id={'firstName'}
                        key={'firstName'}
                        name="firstName"
                        className={styles.textField}
                        required={true}
                        variant="outlined"
                        size={'small'}
                        onChange={handleInput}
                      />
                    </Box>
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>
                        {t('lastName') + ' *'}
                      </h4>
                      <TextField
                        id={'lastName'}
                        key={'lastName'}
                        name="lastName"
                        className={styles.textField}
                        required={true}
                        variant="outlined"
                        size={'small'}
                        onChange={handleInput}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>{t('phone') + ' *'}</h4>
                      <TextField
                        id={'phone'}
                        key={'phone'}
                        name="phone"
                        className={styles.textField}
                        required={true}
                        variant="outlined"
                        size={'small'}
                        onChange={handleInput}
                      />
                    </Box>
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>{t('email') + ' *'}</h4>
                      <TextField
                        id={'email'}
                        key={'email'}
                        name="email"
                        className={styles.textField}
                        required={true}
                        variant="outlined"
                        size={'small'}
                        onChange={handleInput}
                        value={professionalData.email}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>{t('center') + ' *'}</h4>
                      <TextField
                        id={'centerID'}
                        key={'centerID'}
                        name="centerID"
                        className={styles.textField}
                        variant="outlined"
                        size={'small'}
                        onChange={handleInput}
                      />
                    </Box>
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>{t('role') + ' *'}</h4>
                      <Select
                        className={styles.selectField}
                        id="roleID"
                        key="roleID"
                        name="roleID"
                        onChange={handleSelectRole}
                      >
                        {professionalRoles?.map((item) => (
                          <MenuItem value={item.id}>{item.role}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <Box className={styles.textFieldBox}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>
                        {t('autonomousCommunity')}*
                      </h4>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={communities}
                        sx={{
                          width: 230,
                          '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontFamily: `Open-sans, sans-serif !important`,
                          },
                        }}
                        value={{ label: professionalData.autonomousCommunity }}
                        onChange={(_, b) =>
                          setProfessionalData(
                            Object.assign(
                              { ...professionalData },
                              { autonomousCommunity: b?.label || '' },
                              { province: '' }
                            )
                          )
                        }
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </Box>
                    <Box className={styles.textFieldBox} style={{ marginLeft: 40 }}>
                      <h4 style={{ fontFamily: 'Open-sans, sans-serif' }}>
                        {t('province') + ' *'}
                      </h4>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={{
                          label: professionalData.province,
                          value: professionalData.province,
                        }}
                        options={
                          professionalData.autonomousCommunity == ''
                            ? provinces
                            : provinces.filter(
                                (p) => p.value == professionalData.autonomousCommunity
                              )
                        }
                        sx={{
                          width: 230,
                          '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontFamily: `Open-sans, sans-serif !important`,
                          },
                        }}
                        onChange={(_, b) =>
                          setProfessionalData(
                            Object.assign(
                              { ...professionalData },
                              { autonomousCommunity: b?.value || '' },
                              { province: b?.label || '' }
                            )
                          )
                        }
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    padding: '20px',
                    columnGap: '1rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <AiOutlineArrowLeft
                      size={28}
                      style={{ marginTop: '0.6%' }}
                      onClick={() => props.goToLogin()}
                    />
                  </a>
                  <AppButton
                    theme={ButtonTheme.NewPrimaryLight}
                    type={'button'}
                    label={t('register')}
                    handler={validateRegister}
                  />
                </Box>
              </form>
            </FormCard>
          </Box>
          <Modal
            open={modalOpened}
            onClose={() => {
              if (modalMustAllAccept) {
                return
              }
              setLegalTermsAccepted(false)
              setModalOpened(false)
            }}
          >
            <LegalTermsModal
              handleChangeCheck={() => setLegalTermsAccepted(!legalTermsAccepted)}
              title={t('privacyPoliticProfesionalRegister')}
              warningText={t('professionalRegisterCheck')}
              linkOnWarning={true}
              handleClose={() => {
                setLegalTermsAccepted(false)
                setModalOpened(false)
              }}
              handleSave={handleConfirm}
              handleTermsOfUse={handleTermsOfUse}
              handleDataTreatment={handleDataTreatment}
              handleCookiesPolicy={handleCookiesPolicy}
              modalMustAllAccept={modalMustAllAccept}
            />
          </Modal>
        </div>
        <h3 style={{ marginTop: '1%', color: '#797a7a', fontFamily: 'Gotham' }}>
          Sponsored by Wellspect
        </h3>
      </Container>
      <Dialog open={openModalPass} onClose={handleClose}>
        <DialogTitle>{t('forgotPasswordModalTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('forgotPasswordModalText')}</DialogContentText>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            name="emailRecover"
            label={t('email')}
            id="emailRecover"
            value={recoverEmail}
            onChange={(e) => setRecoverEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendNewPassword}>{t('accept')}</Button>
          <Button onClick={handleClose}>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
