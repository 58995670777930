// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_container__ogplU {\n  margin-bottom: 10px;\n  border-bottom: 3px solid var(--color-light-blue);\n  display: flex;\n  justify-content: space-between;\n}\n\n.Header_header__d\\+igc {\n  font-weight: bold;\n  font-size: 1rem;\n  width: 100%;\n}\n\n.Header_icon__PWZtF {\n  cursor: pointer;\n  width: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gDAAgD;EAChD,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".container {\n  margin-bottom: 10px;\n  border-bottom: 3px solid var(--color-light-blue);\n  display: flex;\n  justify-content: space-between;\n}\n\n.header {\n  font-weight: bold;\n  font-size: 1rem;\n  width: 100%;\n}\n\n.icon {\n  cursor: pointer;\n  width: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Header_container__ogplU",
	"header": "Header_header__d+igc",
	"icon": "Header_icon__PWZtF"
};
export default ___CSS_LOADER_EXPORT___;
