// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Professionals_buttonContainer__rQZNQ {\n  position: relative;\n  display: flex;\n  justify-content: end;\n  top: 20px;\n  right: 20px;\n  z-index: 999;\n}\n\n.Professionals_textField__ENEGP {\n  width: auto;\n  margin-right: 20px !important;\n}\n\n.Professionals_fieldsContainer__-H2VS {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n}\n\n.Professionals_selectField__nm9FV {\n  width: 206px;\n  margin-right: 20px;\n  min-height: 40px;\n  height: 40px;\n}\n\n.Professionals_buttonContainer__rQZNQ {\n  display: flex;\n  padding: 20px;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n\n@media (max-width: 1600px) {\n  .Professionals_textFieldBox__HfLNd {\n    flex-direction: column;\n    width: auto;\n    max-width: 500px;\n    color: #000;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/features/professionals/Professionals.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,oBAAoB;EACpB,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE;IACE,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".buttonContainer {\n  position: relative;\n  display: flex;\n  justify-content: end;\n  top: 20px;\n  right: 20px;\n  z-index: 999;\n}\n\n.textField {\n  width: auto;\n  margin-right: 20px !important;\n}\n\n.fieldsContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n}\n\n.selectField {\n  width: 206px;\n  margin-right: 20px;\n  min-height: 40px;\n  height: 40px;\n}\n\n.buttonContainer {\n  display: flex;\n  padding: 20px;\n  column-gap: 1rem;\n  justify-content: flex-end;\n}\n\n@media (max-width: 1600px) {\n  .textFieldBox {\n    flex-direction: column;\n    width: auto;\n    max-width: 500px;\n    color: #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "Professionals_buttonContainer__rQZNQ",
	"textField": "Professionals_textField__ENEGP",
	"fieldsContainer": "Professionals_fieldsContainer__-H2VS",
	"selectField": "Professionals_selectField__nm9FV",
	"textFieldBox": "Professionals_textFieldBox__HfLNd"
};
export default ___CSS_LOADER_EXPORT___;
