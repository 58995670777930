import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import {
  CIS110,
  CIS111,
  CIS112,
  CIS113,
  CIS210,
  CIS211,
  CIS212,
  CIS220,
  CIS221,
  CIS230,
  CIS231,
  CIS232,
  CIS240,
  CIS310,
  CIS320,
  CIS330,
  CIS331,
} from '../../features/professional-educational'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'

export function EducationalsProfessionalIntestinal(props: RouteProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState<number>(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <CurrentNavHeader title={'Módulo 1. Introducción'} />
      <Box
        className={generic.pageContainer}
        style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
        <Box
          className={generic.pageContainer}
          style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
          {activeStep === 0 && <CIS110 />}
          {activeStep === 1 && <CIS111 />}
          {activeStep === 2 && <CIS112 />}
          {activeStep === 3 && <CIS113 />}
          {activeStep === 4 && <CIS210 />}
          {activeStep === 5 && <CIS211 />}
          {activeStep === 6 && <CIS212 />}
          {activeStep === 7 && <CIS220 />}
          {activeStep === 8 && <CIS221 />}
          {activeStep === 9 && <CIS230 />}
          {activeStep === 10 && <CIS231 />}
          {activeStep === 11 && <CIS232 />}
          {activeStep === 12 && <CIS240 />}
          {activeStep === 13 && <CIS310 />}
          {activeStep === 14 && <CIS320 />}
          {activeStep === 15 && <CIS330 />}
          {activeStep === 16 && <CIS331 />}

          <MobileStepper
            style={{ display: 'flex', transform: 'translate(175%, 50%)' }}
            variant="dots"
            steps={17}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400, flexGrow: 1 }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === 16}>
                {t('next')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {t('back')}
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  )
}
