import { UrinationDTO } from './UrinationDTO'
import {
  DrinkType,
  Emptying,
  FoodType,
  Incontinence,
  Loss,
  LossQuantity,
  Quantity,
  Urgency,
} from '../enum/Urine'

export class Urination {
  private readonly _id: string
  private readonly _patientId: string
  private readonly _date: Date
  private readonly _drinkType: string
  private readonly _drinkQuantity: string
  private readonly _drinkDate: Date
  private readonly _foodType: FoodType
  private readonly _food: string
  private readonly _gramQuantity: number
  private readonly _waterQuantity: number
  private readonly _foodDate: Date
  private readonly _emptying: string
  private readonly _emptyingQuantity: string
  private readonly _emptyingDate: Date
  private readonly _urgency: Urgency
  private readonly _loss: Loss
  private readonly _lossQuantity: LossQuantity
  private readonly _lossDate: Date
  private readonly _incontinence: Incontinence
  private readonly _observations: string
  private readonly _image: string

  constructor(p: UrinationDTO) {
    this._id = p.id
    this._patientId = p.patientId
    this._date = p.date
    this._drinkDate = p.drinkDate
    this._drinkType = p.drinkType
    this._drinkQuantity = p.drinkQuantity
    this._foodType = p.foodType
    this._food = p.food
    this._gramQuantity = p.gramQuantity
    this._waterQuantity = p.waterQuantity
    this._foodDate = p.foodDate
    this._emptying = p.emptying
    this._emptyingQuantity = p.emptyingQuantity
    this._emptyingDate = p.emptyingDate
    this._urgency = p.urgency
    this._loss = p.loss
    this._lossQuantity = p.lossQuantity
    this._lossDate = p.lossDate
    this._incontinence = p.incontinence
    this._observations = p.observations
    this._image = p.image
  }

  get id(): string {
    return this._id
  }

  get image(): string {
    return this._image
  }

  get patientId(): string {
    return this._patientId
  }

  get date(): Date {
    return this._date
  }

  get foodDate(): Date {
    return this._foodDate
  }

  get emptyingDate(): Date {
    return this._emptyingDate
  }

  get drinkType(): string {
    return this._drinkType
  }

  get drinkQuantity(): string {
    return this._drinkQuantity
  }

  get drinkDate(): Date {
    return this._drinkDate
  }

  get foodType(): FoodType {
    return this._foodType
  }

  get food(): string {
    return this._food
  }

  get gramQuantity(): number {
    return this._gramQuantity
  }

  get waterQuantity(): number {
    return this._waterQuantity
  }

  get emptying(): string {
    return this._emptying
  }

  get emptyingQuantity(): string {
    return this._emptyingQuantity
  }

  get urgency(): Urgency {
    return this._urgency
  }

  get loss(): Loss {
    return this._loss
  }

  get lossQuantity(): LossQuantity {
    return this._lossQuantity
  }

  get lossDate(): Date {
    return this._lossDate
  }

  get incontinence(): Incontinence {
    return this._incontinence
  }

  get observations(): string {
    return this._observations
  }
}

export interface UrinationQuery {
  patientID: string
  patientIDs: string[]
  date: Date
}

export function toModel(f: UrinationDTO): Urination {
  return new Urination(f)
}
