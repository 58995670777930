export type { IEducationalService } from './services/EducationalService'

export {
  EDUCATIONAL_MODULE,
  EDUCATIONAL_SERVICE_KEY,
  EDUCATIONAL_API_KEY,
  PATIENTLESSON_MODULE,
  PATIENTLESSON_SERVICE_KEY,
  PATIENTLESSON_API_KEY,
  EDUCATIONAL_USER_MODULE,
  EDUCATIONAL_USER_API_KEY,
  EDUCATIONAL_USER_SERVICE_KEY
} from './container'
