// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Patients_buttonContainer__NOAJ9 {\n  position: relative;\n  display: block;\n  float: right;\n  top: 40px;\n  right: 20px;\n  z-index: 999;\n}\n\n.Patients_tableContainer__DJuaq {\n  position: relative;\n}\n\n.Patients_generalContainer__KliDR {\n  display: flex;\n  justify-content: start;\n  flex-direction: column;\n  margin-top: 0;\n  padding: 40px;\n}\n\n.Patients_buttons__\\+tKTp {\n  display: flex;\n  justify-content: end;\n  flex-direction: row;\n}\n\n.Patients_button__xjuJL {\n  margin-left: 20px;\n}\n\n.Patients_separator__KmS5h {\n  margin-top: 40px;\n  border-top: 1px solid #7c7c7c;\n}\n\n.Patients_countNotifications__9Ej\\+x {\n  margin-left: 15px;\n  color: rgb(26, 150, 255);\n}\n\n.Patients_countNotificationsContainer__1-kW4 {\n  display: flex;\n  flex-direction: row;\n}\n", "",{"version":3,"sources":["webpack://./src/features/patients/Patients.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".buttonContainer {\n  position: relative;\n  display: block;\n  float: right;\n  top: 40px;\n  right: 20px;\n  z-index: 999;\n}\n\n.tableContainer {\n  position: relative;\n}\n\n.generalContainer {\n  display: flex;\n  justify-content: start;\n  flex-direction: column;\n  margin-top: 0;\n  padding: 40px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: end;\n  flex-direction: row;\n}\n\n.button {\n  margin-left: 20px;\n}\n\n.separator {\n  margin-top: 40px;\n  border-top: 1px solid #7c7c7c;\n}\n\n.countNotifications {\n  margin-left: 15px;\n  color: rgb(26, 150, 255);\n}\n\n.countNotificationsContainer {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "Patients_buttonContainer__NOAJ9",
	"tableContainer": "Patients_tableContainer__DJuaq",
	"generalContainer": "Patients_generalContainer__KliDR",
	"buttons": "Patients_buttons__+tKTp",
	"button": "Patients_button__xjuJL",
	"separator": "Patients_separator__KmS5h",
	"countNotifications": "Patients_countNotifications__9Ej+x",
	"countNotificationsContainer": "Patients_countNotificationsContainer__1-kW4"
};
export default ___CSS_LOADER_EXPORT___;
