import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATIENTS_ID } from '../../routes/routes-constants'
import iconNotifOff from '../../assets/higea/Ico_campana.svg'
import iconNotifOn from '../../assets/higea/Ico_campana-naranja.svg'
import { Menu, MenuItem } from '@mui/material'
import { getMessengerContainer } from 'container/messenger-module'
import { Query, QueryParam } from 'common/api/Query'
import { NOTIFICATION_MESSAGE_SERVICE_KEY } from '../../modules/messenger'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'

const loggedUserService = getUserContainer().get(LOGGED_USER_SERVICE_KEY)
const notificationMessagesServices = getMessengerContainer().get(NOTIFICATION_MESSAGE_SERVICE_KEY)

export function Dropdown() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { bellNotif, setBellNotif } = useGlobalContext()
  const [openDropdown, setOpenDropdown] = useState(false)
  const { messagesHeader, setMessagesHeader } = useGlobalContext()
  const { messagesForMe, setMessagesForMe } = useGlobalContext()
  const { allMessages, setAllMessages } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isLoading) {
      return
    }

    notificationMessagesServices
      .getFilteredList(
        new Query({
          query: [new QueryParam('messageOfID', loggedUser?.id || '')],
        })
      )
      .subscribe((res) => {
        setAllMessages(res.items.filter((i) => i.readed === false))
        const ids = res.items
          .filter((i) => i.messageOfID !== loggedUser?.id && i.readed === false)
          .map((r) => r.messageOfID)

        var o = {}
        var len = ids.length
        for (let i = 0; i < len; i++) {
          o[ids[i]] = (o[ids[i]] || 0) + 1
        }

        var sortable = []
        for (let name in o) {
          let n = res.items.filter((i) => i.messageOfID === name)[0]
          sortable.push({
            name: n.messageOfName,
            count: o[name],
            messageOfID: n.messageOfID,
            messageOfPatientID: n.messageOfPatientID,
          })
        }

        setMessagesHeader(sortable)

        setMessagesForMe(
          res.items.filter((i) => i.messageOfID === loggedUser?.id && i.readed === false)
        )

        setIsLoading(false)
      })
  }, [loggedUser, isLoading])

  useEffect(() => {
    setBellNotif(messagesForMe.length > 0 ? iconNotifOn : iconNotifOff)
  }, [messagesHeader, messagesForMe])

  const handleOpenNotifications = () => {
    setOpenDropdown(true)
  }

  const handleCloseNotifications = () => {
    setOpenDropdown(false)
  }

  const handleClick = (m) => {
    navigate(ROUTE_PATIENTS_ID.replace(':id', `${m.messageOfPatientID}`))
    window.location.reload()
  }

  const handleClickMForMe = () => {
    let ms = allMessages.filter((a) => a.messageOfID === loggedUser.id)
    navigate(ROUTE_PATIENTS_ID.replace(':id', `${ms[0].messageOfPatientID}`)).then()
    window.location.reload()
  }

  return (
    <>
      {!isLoading && (
        <>
          <Box onClick={handleOpenNotifications}>
            <img
              src={bellNotif}
              style={{
                cursor: 'pointer',
                width: '15px',
                margin: 4,
              }}
            />
          </Box>
          <Menu
            sx={{ mt: '45px' }}
            open={openDropdown}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleCloseNotifications}
          >
            {messagesForMe.length > 0 && (
              <MenuItem onClick={() => handleClickMForMe()}>
                {`${t('youHave')} ${messagesForMe.length} ${t('unreadMessage3')}`}
              </MenuItem>
            )}
            {/* {messagesHeader.length > 0 &&
              messagesHeader.map((m) => (
                <MenuItem onClick={() => handleClick(m)}>
                  {`${t('unreadMessage1')} ${m.name} ${t('unreadMessage2')} ${m.count} ${t(
                    'unreadMessage3'
                  )}`}
                </MenuItem>
              ))}*/}

            {messagesForMe.length === 0 && <MenuItem>{t('noNotifications')}</MenuItem>}
          </Menu>
        </>
      )}
    </>
  )
}
