import { Box, Grid, CircularProgress } from '@mui/material'
import style from './Main.module.css'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Patient, PatientQuery } from '../../modules/patients/models/Patient'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import Patient_icon from '../../assets/higea/ico-pacientes-num copy.svg'
import NewPatient_icon from '../../assets/higea/ico-nuevo-paciente-white.svg'
import Goals_icon from '../../assets/higea/ico-objetivos-num copy.svg'
import Actions_icon from '../../assets/higea/icon-flechas-circulares.svg'
import pendingSmsIcon from '../../assets/higea/ico-pending-message.svg'
import New_user_icon from '../../assets/higea/img-nuevo-usuario.svg'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_CREATE, ROUTE_GOALS, ROUTE_PATIENTS } from '../../routes/routes-constants'
import { MenuItem, Select, Tooltip } from '@material-ui/core'
import { NotificationBox } from 'components/notificationBox/NotificationBox'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { getUserContainer } from 'container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Notification } from 'modules/notifications/models/Notification'
import { TitleType } from 'modules/notifications/enums/TitleType'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getMessengerContainer } from '../../container/messenger-module'
import { NOTIFICATION_MESSAGE_SERVICE_KEY } from '../../modules/messenger'
import {
  DIAGNOSTIC_SERVICE_KEY,
  PATIENT_DIAGNOSTIC_SERVICE_KEY,
  PATIENT_GOAL_SERVICE_KEY,
} from 'modules/patients/container'
import { Pager } from '../../components/table/types'
import { TransportType } from '../../common/enums/TransportType'
import { PriorityLevel } from '../../modules/notifications/enums/PriorityLevel'
import { DeliveryStatus } from '../../modules/notifications/enums/DeliveryStatus'
import { FILE_SERVICE_KEY, IFileService } from '../../modules/files'
import { getFileContainer } from '../../container/file-module'
import { File as F } from '../../modules/files/models/File'
import Button from '@mui/material/Button'
import { PatientGoalService } from 'modules/patients/services/PatientGoalService'
import { PatientGoalQuery } from 'modules/patients/models/PatientGoal'
import { PatientDiagnosticService } from 'modules/patients/services/PatientDiagnosticService'
import { PatientDiagnostic } from 'modules/patients/models/PatientDiagnostic'
import { DiagnosticService } from 'modules/patients/services/DiagnosticService'
import { Diagnostic } from 'modules/patients/models/Diagnostic'
import { NotificationMessageService } from 'modules/messenger/services/NotificationMessageService'
//@ts-ignore
import { SimpleCalendar } from 'simple-components-calendar'
import AddIcon from '../../assets/higea/icon_anadir_copy_white.svg'
import { Appointment, AppointmentQuery } from '../../modules/appointments/models/Appointment'
import { getAppointmentContainer } from '../../container/appointment-module'
import { AppointmentService } from '../../modules/appointments/services/AppointmentService'
import { APPOINTMENTS_SERVICE_KEY } from '../../modules/appointments'
import {
  AppointmentDTO,
  fromModel as fromModelAppointment,
} from '../../modules/appointments/models/AppointmentDTO'
import { emptyList, ItemList } from '../../common/models/ItemList'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const notificationService =
  getNotificationContainer().get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const patientGoalService = getPatientContainer().get<PatientGoalService>(PATIENT_GOAL_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const patientDiagnosticService = getPatientContainer().get<PatientDiagnosticService>(
  PATIENT_DIAGNOSTIC_SERVICE_KEY
)
const diagnosticService = getPatientContainer().get<DiagnosticService>(DIAGNOSTIC_SERVICE_KEY)
const notificationMessagesServices = getMessengerContainer().get<NotificationMessageService>(
  NOTIFICATION_MESSAGE_SERVICE_KEY
)

type DaysOfWeek = {
  dayNumber: number
  dayName: string
  year: number
  month: number
}

type Days = {
  start: Date
  end: Date
}
export type AdviceInterface = {
  id: string | undefined
  recipientID: string
  transportType: TransportType
  description: string
  title: number
  createdAt: Date
  sendAt: Date
  readed: boolean
  patient: string
  priorityLevel: PriorityLevel
  deliveryStatus: DeliveryStatus
  image: string
  idObject: string
}

const appointmentService =
  getAppointmentContainer().get<AppointmentService>(APPOINTMENTS_SERVICE_KEY)

export const Dashboard = () => {
  const { t } = useTranslation()
  const [days, setDays] = useState<Days>({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59),
  })
  const loggedUser = loggedUserService.get()
  const [items, setItems] = useState<Patient[]>([])
  const [countPatients, setCountPatients] = useState<number>(0)
  const [countGoals, setCountGoals] = useState<number>(0)
  const [countActions, setCountActions] = useState<number>(0)
  const [advices, setAdvices] = useState<AdviceInterface[]>([])
  const [eventTypeChanged, setEventTypeChanged] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [patientsPerPage, setPatientsPerPage] = useState<number>(2)
  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [typeOfAdvice, setTypeOfAdvice] = useState<TitleType>(TitleType.all)
  const { innerWidth } = window
  const [sort, setSort] = useState<SortParam<Patient>>({
    field: 'firstName',
    desc: true,
  })
  const dayNames = [
    t('shortSunday'),
    t('shortMonday'),
    t('shortTuesday'),
    t('shortWednesday'),
    t('shortThursday'),
    t('shortFriday'),
    t('shortSaturday'),
  ]
  const [patientDiagnositc, setPatientDiagnostic] = useState<PatientDiagnostic[]>([])
  const [diagnositc, setDiagnostic] = useState<Diagnostic[]>([])
  const [pager, setPager] = useState<Pager>()
  const [showMore, setShowMore] = useState<boolean>(false)
  const [patients, setPatients] = useState<Patient[]>([])
  const [patients2, setPatients2] = useState<string[]>([])
  const [allNotifications, setAllNotifications] = useState<Notification[]>([])
  const [appointments, setAppointments] = useState<Appointment[]>([])

  useEffect(() => {
    if (!loggedUser?.id) return
    appointmentService
      .getAppointmentsByCreatedByPatient(
        new Query({
          query: [new QueryParam<AppointmentQuery>('createdBy', loggedUser?.id || '')],
          pager: { offset: page * patientsPerPage, limit: 100 },
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setAppointments(res.items)
      })
  }, [patients2])

  useEffect(() => {
    if (!loggedUser) {
      return
    }

    notificationMessagesServices
      .getFilteredList(
        new Query({
          query: [new QueryParam('recipientID', loggedUser?.id || '')],
        })
      )
      .subscribe((res) => {
        setCountActions(res.items.filter((i) => i.readed == false).length)
      })
  }, [loggedUser])

  useEffect(() => {
    diagnosticService.getFilteredList(new Query({})).subscribe((res) => {
      setDiagnostic(res.items)
    })
  }, [])

  useEffect(() => {
    patientDiagnosticService.getFilteredItems(new Query({})).subscribe((res) => {
      res && setPatientDiagnostic(res.items)
    })
  }, [])

  useEffect(() => {
    let week = new Array<DaysOfWeek>()
    let current = new Date()
    current.setDate(current.getDate() - current.getDay() + 1)
    for (var i = 0; i < 7; i++) {
      let currenInn = new Date(current)
      week.push({
        dayNumber: currenInn.getDate(),
        dayName: dayNames[currenInn.getDay()],
        year: currenInn.getFullYear(),
        month: currenInn.getMonth(),
      })
      current.setDate(current.getDate() + 1)
    }

    return () => {
      week = []
    }
  }, [])

  useEffect(() => {
    loggedUser &&
      userService.getPatientsByDoctor(loggedUser?.id || '').subscribe((res) => {
        if (res) {
          setPatients(res)
          setPatients2(res.map((p) => p.id))
        }
      })
  }, [loggedUser])

  useEffect(() => {
    if (patients2.length > 0) {
      notificationService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'patientIDs',
                value: patients2,
              },
            ],
          })
        )
        .subscribe((res) => {
          setAllNotifications(res.items)
        })
    }
  }, [patients2])

  useEffect(() => {
    if (patients.length > 0 && patients2.length > 0 && allNotifications.length > 0) {
      getNotifications()
    }
  }, [patients, patients2, allNotifications, days, typeOfAdvice])

  const getNotifications = async () => {
    setEventTypeChanged(true)
    let advicesInter: AdviceInterface[] = []

    if (typeOfAdvice == TitleType.all) {
      let auxAdvices: Notification[] = []
      let notificationsFiltered = allNotifications.filter(
        (item) =>
          item.sendAt.getTime() >= days.start.getTime() &&
          item.sendAt.getTime() <= days.end.getTime()
      )

      notificationsFiltered.forEach(async (item, i) => {
        auxAdvices.push(item)
        let patientsAux = patients?.filter((p) => p.id == item.recipientID)
        let patient =
          patientsAux &&
          patientsAux[0].firstName + ' ' + patientsAux[0].lastName + ' ' + patientsAux[0].internalID

        let image = await fileService
          .getFilteredItems(
            new Query({
              query: [
                new QueryParam<F>('ownerID', (patientsAux && patientsAux[0].userID) || ''),
                new QueryParam<F>('name', 'profileImage'),
              ],
            })
          )
          .toPromise()

        advicesInter.push({
          id: item.id,
          recipientID: item.recipientID,
          transportType: item.transportType,
          description: item.description,
          title: item.title,
          createdAt: item.createdAt,
          sendAt: item.sendAt,
          readed: item.readed,
          patient: patient || '',
          priorityLevel: item.priorityLevel,
          deliveryStatus: item.deliveryStatus,
          image: image.items.length > 0 ? image.items[0].data : '',
          idObject: item.idObject,
        })

        if (i == notificationsFiltered.length - 1) {
          setAdvices(advicesInter)
        }
      })
    } else {
      let res: Notification[] = allNotifications.filter(
        (item) =>
          item.title == typeOfAdvice &&
          item.sendAt.getTime() >= days.start.getTime() &&
          item.sendAt.getTime() <= days.end.getTime()
      )

      let auxAdvices: Notification[] = []
      res.forEach(async (item, i) => {
        auxAdvices.push(item)
        let patientsAux = patients?.filter((p) => p.id == item.recipientID)
        let patient = patientsAux && patientsAux[0].firstName + ' ' + patientsAux[0].internalID

        let image = await fileService
          .getFilteredItems(
            new Query({
              query: [
                new QueryParam<F>('ownerID', (patientsAux && patientsAux[0].userID) || ''),
                new QueryParam<F>('name', 'profileImage'),
              ],
            })
          )
          .toPromise()

        advicesInter.push({
          id: item.id,
          recipientID: item.recipientID,
          transportType: item.transportType,
          description: item.description,
          title: item.title,
          createdAt: item.createdAt,
          sendAt: item.sendAt,
          readed: item.readed,
          patient: patient || '',
          priorityLevel: item.priorityLevel,
          deliveryStatus: item.deliveryStatus,
          image: image.items.length > 0 ? image.items[0].data : '',
          idObject: item.idObject,
        })

        if (i == res.length - 1) {
          setAdvices(advicesInter)
        }
      })
    }
    setEventTypeChanged(false)
  }

  useEffect(() => {
    if (!isLoading) {
      return
    }
    patientService
      .getFilteredList(
        new Query({
          pager: { offset: page * patientsPerPage, limit: patientsPerPage },
          query: [new QueryParam<PatientQuery>('createdBy', loggedUser?.id || '')],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCountPatients(res.count)
        setCount(res.count)

        let patientsIDs = res.items.map((i) => i.id)
        if (patientsIDs.length > 0) {
          patientGoalService
            .getFilteredList(
              new Query({
                query: [new QueryParam<PatientGoalQuery>('patientIDs', patientsIDs)],
              })
            )
            .subscribe((res) => {
              setCountGoals(res.count)
            })
        }
      })
  }, [isLoading])

  const fields: Field<Patient>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('name'),
      name: 'firstName',
      renderFunc: (f, i) => i.firstName + ' ' + i.lastName,
    },
    {
      searchable: true,
      sortable: true,
      label: t('ID'),
      name: 'internalID',
    },
    {
      searchable: true,
      sortable: true,
      label: t('pathology'),
      name: 'contactPhone',
      renderFunc: (f, i) =>
        patientDiagnositc.filter((pt) => pt.patientID == i.id).length > 0 && diagnositc.length > 0
          ? diagnositc.filter(
              (d) => d.id == patientDiagnositc.filter((pt) => pt.patientID == i.id)[0].diagnosticID
            )[0]?.name
          : '',
    },
  ]

  const handlePatientRoute = () => navigate(ROUTE_PATIENTS)
  const handleGoalsRoute = () => navigate(ROUTE_GOALS)

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      handleChangeRowsPerPage: handleChangeRowsPerPage,
    })
  }, [page, count, patientsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPatientsPerPage(2)
  const handlerShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box className={style.gridContainer} style={{ marginBottom: 40 }}>
          <Grid container spacing={2} columns={10}>
            <Grid item container spacing={2} columns={8} alignItems={'center'} mb={4}>
              <Grid item xs={12} lg={4.78} md={12}>
                <Box className={style.gridPatients}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2 style={{ marginBottom: 0 }}>{t('patients')}</h2>
                    <AppButton
                      theme={ButtonTheme.NewPrimaryLight}
                      type={'button'}
                      label={t('new')}
                      handler={() => navigate(ROUTE_PATIENTS + '/' + ROUTE_CREATE)}
                      startIcon={AddIcon}
                    />
                  </Box>
                  {<PatientTable items={items} fields={fields} pager={pager} />}
                </Box>
              </Grid>
              <Grid
                item
                container
                flexDirection={'row'}
                style={{ height: window.innerWidth > 1200 ? '100%' : 'auto', marginTop: 0 }}
                spacing={2}
                xs={12}
                md={12}
                lg={4.8}
              >
                <Grid item xs={3.99} md={3.99} lg={3.99} style={{ paddingTop: 0 }}>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <Box className={style.gridNumbers} onClick={handlePatientRoute}>
                      <img
                        src={Patient_icon}
                        alt="Patients"
                        width="90"
                        height="90"
                        //style={{ marginRight: 8 }}
                      />
                      <Box
                        display="flex"
                        flexDirection={'column'}
                        alignItems={'center'}
                        textAlign={'center'}
                      >
                        <h1 style={{ margin: 0, fontFamily: 'Open-sans, sans-serif' }}>
                          {countPatients}
                        </h1>
                        <h4 style={{ margin: 0 }}>{t('patients')}</h4>
                      </Box>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={3.99} md={3.99} lg={3.99} style={{ paddingTop: 0 }}>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <Box className={style.gridNumbers} onClick={handleGoalsRoute}>
                      <img
                        src={Goals_icon}
                        alt="Goals"
                        width="90"
                        height="90"
                        //style={{ marginRight: 8 }}
                      />
                      <Box
                        display="flex"
                        flexDirection={'column'}
                        alignItems={'center'}
                        textAlign={'center'}
                      >
                        <h1 style={{ margin: 0, fontFamily: 'Open-sans, sans-serif' }}>
                          {countGoals}
                        </h1>
                        <h4 style={{ margin: 0 }}>{t('objectives')}</h4>
                      </Box>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={3.99} md={3.99} lg={3.99} style={{ paddingTop: 0 }}>
                  <Box className={style.gridNumbers}>
                    <Tooltip title={t('smsPendingText')} placement="top">
                      <img
                        src={pendingSmsIcon}
                        alt="Actions"
                        width="90"
                        height="90"
                        //style={{ marginRight: 8 }}
                      />
                    </Tooltip>
                    <Box
                      display="flex"
                      flexDirection={'column'}
                      alignItems={'center'}
                      textAlign={'center'}
                    >
                      <h1 style={{ margin: 0, fontFamily: 'Open-sans, sans-serif' }}>
                        {countActions}
                      </h1>
                      <h4 style={{ margin: 0 }}>{t('pendingMessages')}</h4>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {/*<Grid
                item
                container
                flexDirection={'column'}
                spacing={2}
                xs={window.innerWidth > 400 ? 4 : 12}
                md={4}
                lg={1.6}>*/}
              {/*<Grid item>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <Box
                      className={style.gridNumbersNewPatient}
                      onClick={() => navigate(ROUTE_PATIENTS + '/' + ROUTE_CREATE)}>
                      <img src={NewPatient_icon} alt="Goals" width="80" height="80" />
                      <h4 style={{ color: '#fff' }}>{t('newPatient')}</h4>
                    </Box>
                  </a>
                </Grid>*/}
              {/*  </Grid>*/}
              {/*<Grid
                  item
                  container
                  md={4}
                  direction={'column'}
                  spacing={2}
                  style={{ marginTop: innerWidth < 900 ? '3%' : '' }}>
                <Grid item xs={3} style={{maxWidth:'37.5%'}}>
                  <Box className={style.gridNumbers}>
                    <img src={Actions_icon} alt="Actions" width="50" height="50" />
                    <Box display="flex" flexDirection="column">
                      <h1>{countActions}</h1>
                      <h4>{t('pendingMessages')}</h4>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3} style={{maxWidth:'37.5%'}}>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <Box
                        className={style.gridNumbersNewPatient}
                        onClick={() => navigate(ROUTE_PATIENTS + '/' + ROUTE_CREATE)}>
                      <img src={NewPatient_icon} alt="Goals" width="50" height="50" />
                      <h4 style={{ color: '#fff' }}>{t('newPatient')}</h4>
                    </Box>
                  </a>
                </Grid>
              </Grid>*/}
            </Grid>
            {/*<Grid
              item
              container
              spacing={2}
              columns={2}
              style={{ maxWidth: '350px' }}
              display={'flex'}
              flexDirection={'row'}>
              <Grid item xs={2} rowSpacing={1}>
                <Box className={style.gridNewPatientBox}>
                  <img src={New_user_icon} alt="new patient" width="150" height="150" />
                  <AppButton
                    label={t('newPatient')}
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    handler={() => navigate(ROUTE_PATIENTS + '/' + ROUTE_CREATE)}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>*/}
            <Grid item style={{ width: '101%', marginLeft: '-0.45%' }}>
              <Box className={style.noticePanelGrid}>
                <Grid container spacing={4} columns={10}>
                  <Grid item xs={7}>
                    <h2>{t('noticeBoard')}</h2>
                  </Grid>
                </Grid>
                <SimpleCalendar
                  appointments={appointments}
                  onChangeDates={(date: any) => {
                    if (date.length > 0) {
                      setAdvices([])
                      setDays({
                        start: date[0],
                        end: date[date.length - 1],
                      })
                    } else {
                      setAdvices([])
                      setDays({
                        start: date.start,
                        end: date.end,
                      })
                    }
                  }}
                />
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={typeOfAdvice}
                  disableUnderline
                  style={{
                    marginTop: '3.5%',
                    marginLeft: '2%',
                    fontFamily: 'Open-sans, sans-serif',
                  }}
                  onChange={(e: any) => {
                    setAdvices([])
                    setTypeOfAdvice(e.target.value)
                  }}
                >
                  <MenuItem value={TitleType.newFecalDiary}>{t('newFecalDiary')}</MenuItem>
                  <MenuItem value={TitleType.newMicturitionDiary}>
                    {t('newMicturitionDiary')}
                  </MenuItem>
                  <MenuItem value={TitleType.completedHealthSurvey}>
                    {t('completedHealthSurvey')}
                  </MenuItem>
                  <MenuItem value={TitleType.formativeUnitCompleted}>
                    {t('formativeUnitCompleted')}
                  </MenuItem>
                  <MenuItem value={TitleType.registeredSymtom}>{t('registeredSymtom')}</MenuItem>
                  <MenuItem value={TitleType.eventCalendarDate}>{t('eventCalendarDate')}</MenuItem>
                  <MenuItem value={TitleType.all}>{t('showAll')}</MenuItem>
                </Select>
                {!eventTypeChanged ? (
                  <>
                    {advices.map(function (advice, i) {
                      if (!showMore) {
                        if (i < 3) {
                          return <NotificationBox key={advice.id} advice={advice} />
                        }
                      } else {
                        return <NotificationBox key={advice.id} advice={advice} />
                      }
                    })}
                    {advices.length > 0 && (
                      <Box className={style.noticePanelGrid2} onClick={handlerShowMore}>
                        <Button style={{ fontFamily: 'Open-sans, sans-serif' }} variant="text">
                          {!showMore ? t('showMore') : t('showLess')}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

interface PatientTableParams {
  items: Patient[]
  fields: Field<Patient>[]
  pager: Pager | undefined
}

const PatientTable = (props: PatientTableParams) => {
  return (
    <Box style={{ marginTop: -10, padding: 0 }} className={style.tableContainer}>
      <AppTable
        dashboardStyles={true}
        items={props.items}
        fields={props.fields}
        pager={props.pager}
        rowKeyField={'id'}
      />
    </Box>
  )
}
