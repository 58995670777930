// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".generic_pageContainer__Hz-Us {\n  margin-left: 1rem;\n  height: 100%;\n}\n\n.generic_tableContainer__R116\\+ {\n  background-color: white;\n  border-radius: 4px;\n}\n\n.generic_gridDates__pXKTi {\n  padding-top: 45;\n  padding-left: 50;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/generic.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".pageContainer {\n  margin-left: 1rem;\n  height: 100%;\n}\n\n.tableContainer {\n  background-color: white;\n  border-radius: 4px;\n}\n\n.gridDates {\n  padding-top: 45;\n  padding-left: 50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "generic_pageContainer__Hz-Us",
	"tableContainer": "generic_tableContainer__R116+",
	"gridDates": "generic_gridDates__pXKTi"
};
export default ___CSS_LOADER_EXPORT___;
