// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View_articleWrapper__dD3Za {\n  width: 80%;\n  margin-right: auto;\n  margin-left: auto;\n  display: flex block;\n  flex-direction: column;\n  row-gap: 1rem;\n}\n\n.View_title__T8GWE {\n  font-size: 2.5rem;\n  text-align: center;\n  border-bottom: 1px solid darkgray;\n  padding-bottom: 1.2rem;\n  overflow: hidden;\n}\n\n.View_tagLink__m9fKN {\n  padding: 0 0.5rem;\n}\n\n.View_tags__ORNOE {\n  font-size: 1rem;\n  border-bottom: 1px solid darkgray;\n  padding-bottom: 2rem;\n}\n\n.View_content__Hq7jL {\n  text-align: justify;\n  font-size: 1.2rem;\n}\n\n.View_files__owHQ2 {\n  border-top: 1px solid darkgray;\n}\n\n.View_backButton__fT9VJ {\n  justify-content: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/features/article/View.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC;EACjC,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iCAAiC;EACjC,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".articleWrapper {\n  width: 80%;\n  margin-right: auto;\n  margin-left: auto;\n  display: flex block;\n  flex-direction: column;\n  row-gap: 1rem;\n}\n\n.title {\n  font-size: 2.5rem;\n  text-align: center;\n  border-bottom: 1px solid darkgray;\n  padding-bottom: 1.2rem;\n  overflow: hidden;\n}\n\n.tagLink {\n  padding: 0 0.5rem;\n}\n\n.tags {\n  font-size: 1rem;\n  border-bottom: 1px solid darkgray;\n  padding-bottom: 2rem;\n}\n\n.content {\n  text-align: justify;\n  font-size: 1.2rem;\n}\n\n.files {\n  border-top: 1px solid darkgray;\n}\n\n.backButton {\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleWrapper": "View_articleWrapper__dD3Za",
	"title": "View_title__T8GWE",
	"tagLink": "View_tagLink__m9fKN",
	"tags": "View_tags__ORNOE",
	"content": "View_content__Hq7jL",
	"files": "View_files__owHQ2",
	"backButton": "View_backButton__fT9VJ"
};
export default ___CSS_LOADER_EXPORT___;
