import i18n from '../../i18n'

export enum RoleType {
  Admin = 1,
  Medico,
  Enfermera,
  Paciente,
  Cuidador,
}

export const roleTypes = (): Record<RoleType, string> => ({
  [RoleType.Admin]: i18n.t('admin'),
  [RoleType.Medico]: i18n.t('medico'),
  [RoleType.Enfermera]: i18n.t('enfermera'),
  [RoleType.Paciente]: i18n.t('patient'),
  [RoleType.Cuidador]: i18n.t('cuidador'),
})
