// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurrentNavHeader_currentNavHeaderContainer__qANeP {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 4rem;\n}\n\n.CurrentNavHeader_currentNavHeaderContainer__qANeP > * {\n  margin: 0;\n}\n\n.CurrentNavHeader_titleContainer__J9C77 {\n  border-bottom-left-radius: 25px;\n}\n\nh1 {\n  font-weight: bold;\n  font-family: Open-sans, sans-serif !important;\n  color: #000001;\n  font-size: 24px;\n  margin-left: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/current-nav-header/CurrentNavHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,6CAA6C;EAC7C,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".currentNavHeaderContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 4rem;\n}\n\n.currentNavHeaderContainer > * {\n  margin: 0;\n}\n\n.titleContainer {\n  border-bottom-left-radius: 25px;\n}\n\nh1 {\n  font-weight: bold;\n  font-family: Open-sans, sans-serif !important;\n  color: #000001;\n  font-size: 24px;\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currentNavHeaderContainer": "CurrentNavHeader_currentNavHeaderContainer__qANeP",
	"titleContainer": "CurrentNavHeader_titleContainer__J9C77"
};
export default ___CSS_LOADER_EXPORT___;
