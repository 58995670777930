// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EducationalProfessional_lessonContainer__r5OiC {\n  border-color: #0050c4;\n  background-color: white;\n  width: 100%;\n  padding: 10px;\n  margin-left: 5%;\n  margin-right: 5%;\n  border-radius: 10px;\n  border-style: solid;\n  border-width: 1px;\n}\n\n.EducationalProfessional_lessonItem__8ChKs {\n  border-color: #0050c4;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 10px;\n  width: 100%;\n  padding: 10px;\n  margin-left: 10%;\n  margin-right: 15%;\n  background-color: white !important;\n}\n", "",{"version":3,"sources":["webpack://./src/features/professional-educational/EducationalProfessional.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,kCAAkC;AACpC","sourcesContent":[".lessonContainer {\n  border-color: #0050c4;\n  background-color: white;\n  width: 100%;\n  padding: 10px;\n  margin-left: 5%;\n  margin-right: 5%;\n  border-radius: 10px;\n  border-style: solid;\n  border-width: 1px;\n}\n\n.lessonItem {\n  border-color: #0050c4;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 10px;\n  width: 100%;\n  padding: 10px;\n  margin-left: 10%;\n  margin-right: 15%;\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lessonContainer": "EducationalProfessional_lessonContainer__r5OiC",
	"lessonItem": "EducationalProfessional_lessonItem__8ChKs"
};
export default ___CSS_LOADER_EXPORT___;
