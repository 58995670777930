import i18n from '../../../i18n'

export enum FecesQuantity {
  Few,
  Normal,
  Many,
  Undefined,
}

export enum FecesConsistency {
  HardLump = 1,
  SausageShape,
  CrackedSausage,
  SlimSausage,
  SoftDrops,
  Thick,
  Liquid,
  Undefined,
}

export enum FoodType {
  Soups,
  Vegetables,
  Fruits,
  Other,
}

export enum Food {
  Peas,
  MandarinOrange,
}

export const enum Method {
  Natural,
  DigitalSimulation,
  Suppository,
  Laxative,
  Enema,
  Ita,
  Undefined,
}

export const enum Urgency {
  NoUrgency,
  Low,
  Regular,
  High,
  Critical,
}

export const enum Time {
  LessThan30Minutes,
  Between30And60Minutes,
  MoreThan60Minutes,
}

export const enum TypeOfDiary {
  VisitBathroom,
  Leaks,
  FoodsStuff,
}

export const typeOfDiary = (): Record<TypeOfDiary, string> => ({
  [TypeOfDiary.VisitBathroom]: i18n.t('visitBathroom'),
  [TypeOfDiary.Leaks]: i18n.t('leaks'),
  [TypeOfDiary.FoodsStuff]: i18n.t('foodsStuff'),
})

export const timeString = (): Record<Time, string> => ({
  [Time.LessThan30Minutes]: i18n.t('lessThan30Minutes'),
  [Time.Between30And60Minutes]: i18n.t('between30And60Minutes'),
  [Time.MoreThan60Minutes]: i18n.t('moreThan60Minutes'),
})

export const method = (): Record<Method, string> => ({
  [Method.Natural]: i18n.t('natural'),
  [Method.DigitalSimulation]: i18n.t('digitalSimulation'),
  [Method.Laxative]: i18n.t('laxative'),
  [Method.Suppository]: i18n.t('suppository'),
  [Method.Enema]: i18n.t('enema'),
  [Method.Ita]: i18n.t('ita'),
  [Method.Undefined]: i18n.t(''),
})

export const urgency = (): Record<Urgency, string> => ({
  [Urgency.NoUrgency]: i18n.t('noUrgency'),
  [Urgency.Low]: i18n.t('low'),
  [Urgency.Regular]: i18n.t('regular'),
  [Urgency.High]: i18n.t('high'),
  [Urgency.Critical]: i18n.t('critical'),
})

export const fecesQuantity = (): Record<FecesQuantity, string> => ({
  [FecesQuantity.Few]: i18n.t('few'),
  [FecesQuantity.Normal]: i18n.t('normal'),
  [FecesQuantity.Many]: i18n.t('many'),
  [FecesQuantity.Undefined]: i18n.t(''),
})

export const fecesConsistency = (): Record<FecesConsistency, string> => ({
  [FecesConsistency.HardLump]: i18n.t('type1'),
  [FecesConsistency.SausageShape]: i18n.t('type2'),
  [FecesConsistency.CrackedSausage]: i18n.t('type3'),
  [FecesConsistency.SlimSausage]: i18n.t('type4'),
  [FecesConsistency.SoftDrops]: i18n.t('type5'),
  [FecesConsistency.Thick]: i18n.t('type6'),
  [FecesConsistency.Liquid]: i18n.t('type7'),
  [FecesConsistency.Undefined]: i18n.t(''),
})

export const food = (): Record<Food, string> => ({
  [Food.Peas]: i18n.t('peas'),
  [Food.MandarinOrange]: i18n.t('mandarinOrange'),
})
