import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import style from '../../pages/layout/Header.module.css'
import { navigate } from '@reach/router'
import { ROUTE_HOME } from '../../routes/routes-constants'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'

export type LogoutPopUpProps = {
  show: boolean
  id: string
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function MenuListComposition(props: LogoutPopUpProps) {
  const { t } = useTranslation()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const { dataImage } = useGlobalContext()
  const settings = [t('logout')]

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handlerLogout = (_: React.MouseEvent<Element, MouseEvent>) => {
    loggedUserService.logout()
    navigate(ROUTE_HOME).then()
  }

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, width: '-20%', height: '20%' }}>
          {dataImage ? (
            <Avatar
              alt="Avatar"
              src={`data:image/jpeg;base64,${dataImage}`}
              sx={{ width: 25, height: 25, marginLeft: 2, marginBottom: '2px' }}
            />
          ) : (
            <Avatar className={style.avatarNavigator} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handlerLogout}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
