import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Editor as E } from '../../features/appointments/Editor'
import React from 'react'

export function EditorAppointments(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
    </>
  )
}
