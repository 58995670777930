// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Goals_addGoalsContainer__W5iMc {\n  background-color: white;\n  border-radius: 4px;\n  padding: 20px;\n  margin-bottom: 20px;\n  font-family: Open-sans, sans-serif !important;\n}\n\n.Goals_addGoalsTitle__927na {\n  padding: 0;\n  margin: 0;\n  font-size: 24px;\n  color: #000;\n  font-family: Open-sans, sans-serif !important;\n}\n\n.Goals_textField__iDAjG {\n  width: 100%;\n  margin-right: 20px !important;\n  font-family: Open-sans, sans-serif !important;\n}\n", "",{"version":3,"sources":["webpack://./src/features/goals/Goals.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,UAAU;EACV,SAAS;EACT,eAAe;EACf,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,6CAA6C;AAC/C","sourcesContent":[".addGoalsContainer {\n  background-color: white;\n  border-radius: 4px;\n  padding: 20px;\n  margin-bottom: 20px;\n  font-family: Open-sans, sans-serif !important;\n}\n\n.addGoalsTitle {\n  padding: 0;\n  margin: 0;\n  font-size: 24px;\n  color: #000;\n  font-family: Open-sans, sans-serif !important;\n}\n\n.textField {\n  width: 100%;\n  margin-right: 20px !important;\n  font-family: Open-sans, sans-serif !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addGoalsContainer": "Goals_addGoalsContainer__W5iMc",
	"addGoalsTitle": "Goals_addGoalsTitle__927na",
	"textField": "Goals_textField__iDAjG"
};
export default ___CSS_LOADER_EXPORT___;
