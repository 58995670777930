import React, { useEffect, useState, ChangeEvent } from 'react'
import { Box, AppBar, Tabs, Tab, Alert } from '@mui/material'
import generic from '../../assets/generic.module.css'
import style from '../statistics/Stats.module.css'
import { useTranslation } from 'react-i18next'
import { PatientDetailGeneral } from './PatientDetailGeneral'
import { PatientDTO, fromModel } from '../../modules/patients/models/PatientDTO'
import { getPatientContainer } from 'container/patient-module'
import { PatientService } from 'modules/patients/services/PatientService'
import {
  DIAGNOSTIC_SERVICE_KEY,
  PATIENT_DIAGNOSTIC_SERVICE_KEY,
  PATIENT_NOTES_SERVICE_KEY,
  PATIENT_SERVICE_KEY,
  TREATMENT_SERVICE_KEY,
} from 'modules/patients'
import { PatientDetailMedical } from './PatientDetailMedical'
import { Diagnostic, DiagnosticQuery } from 'modules/patients/models/Diagnostic'
import { PatientDiagnosticService } from 'modules/patients/services/PatientDiagnosticService'
import { TreatmentService } from 'modules/patients/services/TreatmentService'
import { PatientDetailNotes } from './PatientDetailNotes'
import { PatientNoteService } from 'modules/patients/services/PatientNoteService'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import styleB from './PatientData.module.css'
import { v4 as uuidv4 } from 'uuid'
import { Query, QueryParam } from '../../common/api/Query'
import { DiagnosticService } from 'modules/patients/services/DiagnosticService'
import { ProblemService } from 'modules/patients/services/ProblemService'
import {
  PATIENT_PROBLEM_SERVICE_KEY,
  PATIENT_TREATMENT_SERVICE_KEY,
  PROBLEM_SERVICE_KEY,
} from 'modules/patients/container'
import { emptyNoteDTO, NoteDTO, fromModel as fromModelNotes } from 'modules/patients/models/NoteDTO'
import { PatientDiagnostic } from 'modules/patients/models/PatientDiagnostic'
import { fromModel as fromModelPT } from 'modules/patients/models/PatientDiagnosticDTO'
import { PatientProblem } from 'modules/patients/models/PatientProblem'
import { PatientProblemService } from 'modules/patients/services/PatientProblemService'
import { fromModel as fromModelProblem } from 'modules/patients/models/PatientProblemDTO'
import { PatientTreatment } from 'modules/patients/models/PatientTreatment'
import { fromModel as fromModelPatientTreatment } from 'modules/patients/models/PatientTreatmentDTO'
import { PatientTreatmentService } from 'modules/patients/services/PatientTreatmentService'
import { UserDTO, fromModel as fromModelUser } from 'modules/users/models/User'
import { getUserContainer } from 'container/user-module'
import { IUserService, USER_SERVICE_KEY } from 'modules/users'
import { toUserGenders, UserGender } from 'modules/users/enums/UserGender'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

type PatientDataProps = {
  id: string
}

interface PatientTreatmentInterface {
  id: string
  patientID: string
  treatmentID: string
  brand: string
  name: string
  checked: boolean
}

interface PatientDiagnosticInterface {
  diagnosticID: string
  patientID: string
  name: string
  text: string
  date: Date
}

interface PatientProblemInterface {
  id: string
  problemID: string
  patientID: string
  name: string
  checked: boolean
}

enum View {
  generalData = 1,
  medicalData,
  notes,
}

const patientTreatmentService = getPatientContainer().get<PatientTreatmentService>(
  PATIENT_TREATMENT_SERVICE_KEY
)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const patientDiagnosticService = getPatientContainer().get<PatientDiagnosticService>(
  PATIENT_DIAGNOSTIC_SERVICE_KEY
)
const treatmentService = getPatientContainer().get<TreatmentService>(TREATMENT_SERVICE_KEY)
const patientNoteService = getPatientContainer().get<PatientNoteService>(PATIENT_NOTES_SERVICE_KEY)
const diagnosticService = getPatientContainer().get<DiagnosticService>(DIAGNOSTIC_SERVICE_KEY)
const problemService = getPatientContainer().get<ProblemService>(PROBLEM_SERVICE_KEY)
const patientProblemService = getPatientContainer().get<PatientProblemService>(
  PATIENT_PROBLEM_SERVICE_KEY
)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export function PatientDetail(props: PatientDataProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tabValue, setTabValue] = useState<number>(0)
  const [view, setView] = useState<View>(View.generalData)
  const [tabsView, setTabsView] = useState<JSX.Element>(<></>)
  const [user, setUser] = useState<UserDTO>()
  const [patient, setPatient] = useState<PatientDTO>()
  const [diagnostics, setDiagnostics] = useState<Diagnostic[]>([])
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [patientDiagnostic, setPatientDiagnostic] = useState<PatientDiagnosticInterface>({
    diagnosticID: '',
    patientID: '',
    name: '',
    text: '',
    date: new Date(),
  })
  const [patientTreatmentsInter, setPatientTreatmentsInter] = useState<PatientTreatmentInterface[]>(
    []
  )
  const [problemsInterface, setProblemsInterface] = useState<PatientProblemInterface[]>([])
  const [notes, setNotes] = useState<NoteDTO>(emptyNoteDTO())
  const [diagnosticBefore, setDiagnosticBefore] = useState<PatientDiagnostic>()
  const [problemsBefore, setProblemsBefore] = useState<PatientProblem[]>([])
  const [patientTreatmentBefore, setPatientTreatmentBefore] = useState<PatientTreatment[]>([])
  const [notesBefore, setNotesBefore] = useState<NoteDTO>()

  const getPatientProblems = async () => {
    let patientProblems = await patientProblemService
      .getFilteredItems(
        new Query({
          query: [
            {
              name: 'patientID',
              value: patient?.id || '',
            },
          ],
        })
      )
      .toPromise()

    setProblemsBefore(patientProblems.items)

    problemService.getFilteredList(new Query({})).subscribe((res) => {
      let problemsAux: PatientProblemInterface[] = []
      res.items.forEach((item) => {
        problemsAux.push({
          id: uuidv4(),
          problemID: item.id,
          patientID: patient?.id || '',
          name: item.name,
          checked: patientProblems.items.map((i) => i.problemID).includes(item.id),
        })
      })
      setProblemsInterface(problemsAux)
    })
  }

  useEffect(() => {
    if (patient && firstTime) {
      userService.getByID(patient.userID || '').subscribe((res) => {
        if (res) {
          setUser(fromModelUser(res))
        }
      })

      patientDiagnosticService
        .getPatientDiagnosticByPatientID(patient.id)
        .subscribe(async (res) => {
          diagnosticService.getByID(res?.diagnosticID)
          if (res) {
            let diagnostic = await diagnosticService.getByID(res?.diagnosticID).toPromise()
            setDiagnosticBefore(res)
            setPatientDiagnostic({
              diagnosticID: res?.diagnosticID,
              patientID: patient.id,
              name: diagnostic.name,
              text: res.text,
              date: new Date(res.createdAt),
            })
          }
        })

      getPatientProblems()

      patientNoteService.getNotesByPatientID(props.id).subscribe((res) => {
        if (res && res.length > 0) {
          setNotes(fromModelNotes(res[0]))
          setNotesBefore(fromModelNotes(res[0]))
        }
      })
      setIsLoading(false)
      setFirstTime(false)
    }
  }, [patient])

  const getPatientTreatments = async () => {
    if (patient) {
      let patientTBefore = await patientTreatmentService
        .getPatientTreatmentByPatientID(patient.id)
        .toPromise()
      if (patientTBefore) {
        setPatientTreatmentBefore(patientTBefore?.items)
      }

      treatmentService.getFilteredList(new Query({})).subscribe((res) => {
        let ptInterAux: PatientTreatmentInterface[] = []

        res.items.forEach((item) => {
          let exists = patientTBefore?.items.filter((pt) => pt.treatmentID == item.id)

          if (exists && exists?.length > 0) {
            ptInterAux.push({
              id: uuidv4(),
              patientID: patient.id,
              treatmentID: item.id,
              brand: exists?.length > 0 ? exists[0].brand : '',
              name: item.name,
              checked: exists?.length > 0,
            })
          } else {
            ptInterAux.push({
              id: uuidv4(),
              patientID: patient.id,
              treatmentID: item.id,
              brand: '',
              name: item.name,
              checked: false,
            })
          }

          setPatientTreatmentsInter(ptInterAux)
        })
      })
    }
  }

  useEffect(() => {
    if (patient && firstTime) {
      let auxPatientDiagnostic = patientDiagnostic
      if (auxPatientDiagnostic) {
        auxPatientDiagnostic.patientID = patient.id
      }
      setPatientDiagnostic(auxPatientDiagnostic)

      getPatientTreatments()
    }
  }, [patient])

  useEffect(() => {
    const query: QueryParam<DiagnosticQuery>[] = []
    diagnosticService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setDiagnostics(res.items)
      })
  }, [])

  useEffect(() => {
    patientService.getByID(props.id || '').subscribe((res) => {
      setPatient(fromModel(res))
    })
  }, [])

  useEffect(() => {
    patient && user && setTabsView(getView(view))
  }, [view, patient, user])

  const handleNoteInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotes(Object.assign({ ...notes }, { content: e.target.value }))
  }

  const handleNoteDate = (e: MaterialUiPickersDate) => {
    if (e) {
      setNotes(Object.assign({ ...notes }, { date: new Date(e.toDate()) }))
    }
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (patient) {
      setPatient(Object.assign({ ...patient }, { [e.target.name]: e.target.value }))
    }
  }

  const handleChangePatientTreatment = (ts: PatientTreatmentInterface[]) => {
    setPatientTreatmentsInter(ts)
  }

  const handleChangeDiagnostic = (d: PatientDiagnosticInterface) => {
    setPatientDiagnostic(d)
  }

  const handleChangeProblems = (newProblems: PatientProblemInterface[]) => {
    setProblemsInterface(newProblems)
  }

  const handleChangePatientData = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (patient) {
      setPatient(Object.assign({ ...patient }, { [e.target.name]: e.target.value }))
    }
  }

  const handleChangeGender = (e: string) => {
    if (patient) {
      if (e == t('male')) {
        setPatient(Object.assign({ ...patient }, { ['gender']: UserGender.Male }))
        return
      }
      if (e == t('female')) {
        setPatient(Object.assign({ ...patient }, { ['gender']: UserGender.Female }))
        return
      }
      if (e === t('noneOfTheAbove')) {
        setPatient(Object.assign({ ...patient }, { ['gender']: UserGender.NoneOfTheAbove }))
        return
      }
    }
  }

  const handleChangeDOB = (e: Date) => {
    if (patient) {
      setPatient(Object.assign({ ...patient }, { dob: e }))
    }
  }

  const getView = (view: View): JSX.Element => {
    switch (view) {
      case View.generalData:
        if (patient) {
          return (
            <PatientDetailGeneral
              patient={patient}
              handleChangeDOB={handleChangeDOB}
              handleChangeGender={handleChangeGender}
              handleChangePatientData={handleChangePatientData}
              handleChangeCommunity={(com) => {
                if (user) {
                  setUser(Object.assign({ ...user }, { autonomousCommunity: com }))
                }
                setPatient(Object.assign({ ...patient }, { autonomousCommunity: com }))
              }}
              handleChangeProvince={(prov) => {
                if (user) {
                  setUser(Object.assign({ ...user }, { province: prov }))
                }
                setPatient(Object.assign({ ...patient }, { province: prov }))
              }}
              handleChangeProvinceCommunity={(prov, com) => {
                if (user) {
                  let newUs = Object.assign({ ...user }, { province: prov })
                  let newUs2 = Object.assign({ ...newUs }, { autonomousCommunity: com })
                  setUser(newUs2)
                }
                let newPat = Object.assign({ ...patient }, { province: prov })
                let newPat2 = Object.assign({ ...newPat }, { autonomousCommunity: com })
                setPatient(newPat2)
              }}
            />
          )
        } else {
          return <></>
        }
      case View.medicalData:
        if (patient) {
          return (
            <PatientDetailMedical
              patient={patient}
              diagnostics={diagnostics}
              patientDiagnostic={patientDiagnostic}
              handlePatientInput={handleInput}
              handleChangePatientTreatment={handleChangePatientTreatment}
              allPatientTreatmentsInter={patientTreatmentsInter}
              problemsInterface={problemsInterface}
              handleChangeDiagnostic={handleChangeDiagnostic}
              handleChangeProblems={handleChangeProblems}
            />
          )
        } else {
          return <></>
        }
      case View.notes:
        if (patient) {
          return (
            <PatientDetailNotes
              note={notes}
              handleNoteInput={handleNoteInput}
              handleNoteDate={handleNoteDate}
            />
          )
        } else {
          return <></>
        }
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTabValue(tabNumber)
    switch (tabNumber) {
      case 0:
        setView(View.generalData)
        break
      case 1:
        setView(View.medicalData)
        break
      case 2:
        setView(View.notes)
        break
      default:
        break
    }
  }

  const handleSave = () => {
    let unFilled = ''

    if (patient && !patient.email) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('email')
    }

    if (patient && !patient.firstName) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('firstName')
    }

    if (patient && !patient.lastName) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('lastName')
    }

    if (patientDiagnostic.diagnosticID == '') {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('condition')
    }

    let problemsChecked = problemsInterface.filter((p) => p.checked)
    if (problemsChecked.length == 0) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('problem')
    }

    let treatChecked = patientTreatmentsInter.filter((p) => p.checked)
    if (treatChecked.length == 0) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('treatment')
    }

    if (unFilled != '') {
      setErrorMessage(unFilled)
    } else {
      if (patient) {
        patientService.update(patient).subscribe((res) => {
          if (user) {
            userService.update(user)
          }

          if (!notesBefore && notes.content != '') {
            let newNotes = Object.assign({ ...notes }, { patientID: patient.id })
            patientNoteService.add(newNotes)
          } else if (notesBefore) {
            let newNotes = Object.assign({ ...notes }, { id: notesBefore?.id || '' })
            patientNoteService.update(newNotes)
          }
          patientTreatmentsInter.forEach((newItem) => {
            let befores = patientTreatmentBefore.filter(
              (item) => item.treatmentID == newItem.treatmentID
            )
            if (befores.length > 0) {
              if (newItem.checked) {
                if (befores[0].brand != newItem.brand) {
                  patientTreatmentService.update(
                    fromModelPatientTreatment(
                      new PatientTreatment({
                        id: befores[0].id,
                        createdAt: new Date(),
                        treatmentID: befores[0].treatmentID,
                        brand: newItem.brand,
                        patientID: newItem.patientID,
                      })
                    )
                  )
                }
              } else {
                patientTreatmentService.delete(befores[0].id)
              }
            } else {
              if (newItem.checked) {
                patientTreatmentService.add(
                  fromModelPatientTreatment(
                    new PatientTreatment({
                      id: newItem.id,
                      createdAt: new Date(),
                      treatmentID: newItem.treatmentID,
                      brand: newItem.brand,
                      patientID: newItem.patientID,
                    })
                  )
                )
              }
            }
          })

          let problemsBeforeIDs = problemsBefore.map((p) => p.problemID)

          problemsInterface.forEach((problem) => {
            if (problem.checked && !problemsBeforeIDs.includes(problem.problemID)) {
              patientProblemService.add(
                fromModelProblem(
                  new PatientProblem({
                    id: problem.id,
                    createdAt: new Date(),
                    problemID: problem.problemID,
                    patientID: problem.patientID,
                  })
                )
              )
            } else if (!problem.checked) {
              if (problemsBeforeIDs.includes(problem.problemID)) {
                let before = problemsBefore.filter((p) => p.problemID === problem.problemID)
                if (before.length > 0) {
                  patientProblemService.delete(before[0].id)
                }
              }
            }
          })

          if (patientDiagnostic.diagnosticID != '') {
            if (diagnosticBefore) {
              patientDiagnosticService.update(
                fromModelPT(
                  new PatientDiagnostic({
                    id: diagnosticBefore?.id || '',
                    createdAt: diagnosticBefore?.createdAt || new Date(),
                    diagnosticID: patientDiagnostic.diagnosticID,
                    patientID: patientDiagnostic.patientID,
                    text: patientDiagnostic.text,
                  })
                )
              )
            } else {
              patientDiagnosticService.add(
                fromModelPT(
                  new PatientDiagnostic({
                    id: uuidv4(),
                    createdAt: patientDiagnostic.date,
                    diagnosticID: patientDiagnostic.diagnosticID,
                    patientID: patientDiagnostic.patientID,
                    text: patientDiagnostic.text,
                  })
                )
              )
            }
          }

          navigate(ROUTE_PATIENTS_ID.replace(':id', patient.id))
        })
      }
    }
  }

  return (
    <>
      {!isLoading && (
        <Box style={{ marginBottom: 8 }}>
          <Box className={styleB.buttonContainer}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLightWithoutWidth}
              type={'button'}
              label={t('returnToPatient')}
              handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
            />
          </Box>
          <Box className={generic.pageContainer}>
            {errorMessage && (
              <Box mb={3}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t('unfilledFields')}
                  {errorMessage}
                </Alert>
              </Box>
            )}
            <Box className={style.tabContainer}>
              <AppBar position="static" className={style.caltabs}>
                <Tabs value={tabValue} onChange={handleTabChange} className={style.caltabs}>
                  <Tab
                    label={t('generalData')}
                    className={tabValue === 0 ? style.parsetabsActive : style.parsetabs}
                  />
                  <Tab
                    label={t('medicalData')}
                    className={tabValue === 1 ? style.parsetabsActive : style.parsetabs}
                  />
                  <Tab
                    label={t('notes')}
                    className={tabValue === 2 ? style.parsetabsActive : style.parsetabs}
                  />
                </Tabs>
              </AppBar>
              {tabsView}
              <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '25px' }}>
                <Box style={{ marginRight: 15 }}>
                  <AppButton
                    theme={ButtonTheme.BasicTransparentBlue}
                    type={'button'}
                    label={t('cancel')}
                    handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewPrimaryLight}
                  type={'submit'}
                  label={t('save')}
                  handler={handleSave}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
