import {
  DrinkType,
  Emptying,
  FoodType,
  Incontinence,
  Loss,
  LossQuantity,
  Quantity,
  Urgency,
} from '../enum/Urine'
import { v4 as uuidv4 } from 'uuid'
import { Urination } from './Urination'

export interface UrinationDTO {
  id: string
  patientId: string
  date: Date
  drinkType: string
  drinkQuantity: string
  drinkDate: Date
  foodType: FoodType
  food: string
  gramQuantity: number
  waterQuantity: number
  foodDate: Date
  emptying: string
  emptyingQuantity: string
  emptyingDate: Date
  urgency: Urgency
  loss: Loss
  lossDate: Date
  lossQuantity: LossQuantity
  incontinence: Incontinence
  observations: string
  image: string
}

export function emptyUrinationDTO() {
  return {
    id: uuidv4(),
    patientId: '',
    date: new Date(),
    drinkType: -1,
    drinkQuantity: -1,
    drinkDate: new Date(),
    foodType: -1,
    food: '',
    gramQuantity: 0,
    waterQuantity: 0,
    foodDate: new Date(),
    emptying: -1,
    emptyingQuantity: undefined,
    emptyingDate: new Date(),
    urgency: -1,
    loss: -1,
    lossDate: new Date(),
    lossQuantity: -1,
    incontinence: -1,
    observations: '',
    image: '',
  }
}

export function fromModel(urination: Urination): UrinationDTO {
  return {
    id: urination.id,
    patientId: urination.patientId,
    date: urination.date,
    drinkType: urination.drinkType,
    drinkQuantity: urination.drinkQuantity,
    drinkDate: urination.drinkDate,
    foodType: urination.foodType,
    food: urination.food,
    gramQuantity: urination.gramQuantity,
    waterQuantity: urination.waterQuantity,
    foodDate: urination.foodDate,
    emptying: urination.emptying,
    emptyingQuantity: urination.emptyingQuantity,
    emptyingDate: urination.emptyingDate,
    urgency: urination.urgency,
    loss: urination.loss,
    lossDate: urination.lossDate,
    lossQuantity: urination.lossQuantity,
    incontinence: urination.incontinence,
    observations: urination.observations,
    image: urination.image,
  }
}
